import { Model } from "@react3l/react3l/core";
import { Device } from "models/Device";
import { Dormitory } from "models/Dormitory";
import { FirmwareIntegration } from "models/FirmwareIntegration/FirmwareIntegration";
import { Status } from "models/Status";
import { Moment } from "moment";

export class HomeController extends Model {
  public id: string;
  public dormitoryId: string;
  public dormitoryName: string;
  public name: string;
  public macAddress: string;
  public ipAddress: string;
  public wifiName: string;
  public timeZone: number;
  public version: string;
  public versionUpdatedAt: string;
  public isMaster: boolean;
  public createdAt: Moment;
  public updatedAt: Moment;
  public dormitory: Dormitory;
  public devices: Device[];
  public firmwareIntegrations: FirmwareIntegration[];
  public autoUpdate: boolean;
  public homeControllerStatus: Status;
  public homeControllerActiveStatus: Status;
  public deviceQuantity: number;
  public homeControllerTypeId: number;
}
