import { AuthContext } from "app/app-context";
import React from "react";

export const useRoleService = () =>{
    const [appUserInfo] = React.useContext(AuthContext);

    const userRole:number[] = React.useMemo(() => {
      return appUserInfo?.appUserRoleMappings.map(a=>a.roleId)??[];
    }, [appUserInfo]);

    return {
        userRole
    };
};