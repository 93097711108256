import { DateFilter, NumberFilter, StringFilter } from "@react3l/advanced-filters";
import { ModelFilter } from "@react3l/react3l/core";

export class DeviceFilter extends ModelFilter {
    public id?: StringFilter = new StringFilter();
    public name?: StringFilter = new StringFilter();
    public firmwareVersion?: StringFilter = new StringFilter();
    public parentId?: StringFilter = new StringFilter();
    public roomId?: StringFilter = new StringFilter();
    public dormitoryId?: StringFilter = new StringFilter();
    public categoryId?: NumberFilter = new NumberFilter();
    public deviceClassId?: NumberFilter = new NumberFilter();
    public statusId?: NumberFilter = new NumberFilter();
    public homeControllerId?: StringFilter = new StringFilter();
    public createdAt?: DateFilter = new DateFilter();
    public updatedAt?: DateFilter = new DateFilter();
    public deletedAt?: DateFilter = new DateFilter();
    public tuyaIrRemoteId?: StringFilter = new StringFilter();
    public macAddress?: StringFilter = new StringFilter();
    public ipAddress?: StringFilter = new StringFilter();
    public iconId?: StringFilter = new StringFilter();
  }