import { ProtectedRoute, useCheckAuthorized } from "config/protected-route";
import { DEVICE_MASTER_ROUTE } from "config/route-consts";
import React from "react";
import { Switch } from "react-router-dom";
import DeviceMaster from "./DeviceMaster/DeviceMaster";
import "./DeviceView.scss";
function DeviceView() {
  const { auth } = useCheckAuthorized();
  return (
    <Switch>
      <ProtectedRoute
        path={DEVICE_MASTER_ROUTE}
        key={DEVICE_MASTER_ROUTE}
        component={DeviceMaster}
        auth={auth(DEVICE_MASTER_ROUTE)}
      />
    </Switch>
  );
}
export { DeviceMaster };
export default DeviceView;
