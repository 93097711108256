import { SendNotificationTypeEnum } from "config/consts";
import { Notification } from "models/Notification";
import React, { Reducer, useReducer } from "react";
import { INotificationReducerAction, INotificationReducerState, notificationReducer, NotificationReducerActionEnum } from "redux-part/notificationReducer";
import { dormitoryRepository } from "repositories/dormitory-repository";
import { endUserRepository } from "repositories/end-user-repository";
import { homeControllerRepository } from "repositories/home-controller-repository";
import appMessageService, { messageType } from "./app-message-service";

class SendNotificationServive{
    useNotificationForm(sendNotificationType: SendNotificationTypeEnum){
        const [{notification}, dispatch] = useReducer<Reducer<INotificationReducerState, INotificationReducerAction>>(
            notificationReducer, {
                notification:{
                    title:'',
                    content:'',
                    link:'',
                    imageUrl:'',
                    notificationTypeId:1
                }
            }
        );

        const handleChangeTitle = React.useCallback((value: string) => {
            dispatch({
                type: NotificationReducerActionEnum.CHANGE_TITLE,
                notification: {...notification, title: value}
            });
        }, [notification]);

        const handleChangeContent = React.useCallback((value: string) => {
            dispatch({
                type: NotificationReducerActionEnum.CHANGE_CONTENT,
                notification: {...notification, content: value}
            });
        }, [notification]);

        const handleChangeIds = React.useCallback((ids: KeyType[]) => {
            dispatch({
                type: NotificationReducerActionEnum.CHANGE_IDS,
                notification: {...notification, ids: ids}
            });
        }, [notification]);

        const handleChangeImageUrl = React.useCallback((value: string) => {

            dispatch({
                type: NotificationReducerActionEnum.CHANGE_IMAGE,
                notification: {...notification, imageUrl: value}
            });
        }, [notification]);

        const handleChangeLink = React.useCallback((value: string) => {
                dispatch({
                    type: NotificationReducerActionEnum.CHANGE_LINK,
                    notification: {...notification, link: value}
                });
        }, [notification]);

        const isEmpty = (notification: Notification): boolean => {
            if(!notification || !notification.title || !notification.content || !notification.ids || notification.ids.length < 1)
            return true;
            return false;
        };
        const handleSend = React.useCallback(async () => {
            if(isEmpty(notification)){
               appMessageService.messageFactory({
                type: messageType.WARNING,
                title: 'Nhập đủ thông tin!'
               });
               return false;
            }
            let response;
            try {
                notification.imageUrl = new URL(notification.imageUrl).toString();
                notification.link = new URL(notification.link).toString();
            } catch (error) {
                appMessageService.messageFactory({
                    type: messageType.ERROR,
                    title: 'Đường dẫn sai'
                });
                return false;
            }
            switch(sendNotificationType){
                case SendNotificationTypeEnum.EndUser: 
                    response  = await endUserRepository.sendNotification(notification).toPromise();
                break;
                case SendNotificationTypeEnum.Dormitory: 
                    response = await dormitoryRepository.sendNotification(notification).toPromise();
                break;
                case SendNotificationTypeEnum.HomeController: 
                    response = await homeControllerRepository.sendNotification(notification).toPromise();
                break;
            }
            if(response){
                dispatch({
                    type: NotificationReducerActionEnum.RESET_NOTIFICATION,
                });
                appMessageService.messageFactory({
                    type: messageType.SUCCESS,
                    title: 'Gửi thông báo thành công'
                });
            }
            return response;
            }, [notification, sendNotificationType]);
        return {
            notification,
            handleChangeTitle,
            handleChangeContent,
            handleChangeIds,
            handleChangeImageUrl,
            handleChangeLink,
            handleSend
        };
    }
}

export const sendNotificationServive: SendNotificationServive = new SendNotificationServive();
