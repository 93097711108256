import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { AxiosResponse } from "axios";
import { BASE_API_URL } from "config/consts";
import { httpConfig } from "config/http";
import { Moment } from 'moment';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_FIRMWARE_PREFIX } from "config/api-consts";
import { Dormitory, DormitoryFilter } from "models/Dormitory";
import { HomeController, HomeControllerFilter } from "models/HomeController";
import { Firmware, FirmwareFilter } from "../models/Firmware";

export class FirmwareRepository extends Repository {
    constructor() {
        super(httpConfig);
        this.baseURL = url(BASE_API_URL, API_FIRMWARE_PREFIX);
    }

    public count = (filter?: FirmwareFilter): Observable<number> => {
        return this.httpObservable
            .post<number>(kebabCase(nameof(this.count)), filter)
            .pipe(map((response: AxiosResponse<number>) => response.data));
    };

    public list = (
        filter?: FirmwareFilter
    ): Observable<Firmware[]> => {
        return this.httpObservable
            .post<Firmware[]>(kebabCase(nameof(this.list)), filter)
            .pipe(map((response: AxiosResponse<Firmware[]>) => response.data));
    };

    public get = (id: number | string): Observable<Firmware> => {
        return this.httpObservable
            .post<Firmware>(kebabCase(nameof(this.get)), { id })
            .pipe(map((response: AxiosResponse<Firmware>) => response.data));
    };

    public create = (item: Firmware): Observable<Firmware> => {
        return this.httpObservable
            .post<Firmware>(kebabCase(nameof(this.create)), item)
            .pipe(map((response: AxiosResponse<Firmware>) => response.data));
    };

    public update = (item: Firmware): Observable<Firmware> => {
        return this.httpObservable
            .post<Firmware>(kebabCase(nameof(this.update)), item)
            .pipe(map((response: AxiosResponse<Firmware>) => response.data));
    };

    public delete = (item: Firmware): Observable<Firmware> => {
        return this.httpObservable
            .post<Firmware>(kebabCase(nameof(this.delete)), item)
            .pipe(map((response: AxiosResponse<Firmware>) => response.data));
    };

    public save = (
        item: Firmware,
        isDetail: boolean
    ): Observable<Firmware> => {
        return item.id ? this.update(item) : this.create(item);
    };

    public bulkDelete = (idList: number[] | string[]): Observable<void> => {
        return this.httpObservable
            .post(kebabCase(nameof(this.bulkDelete)), idList)
            .pipe(map((response: AxiosResponse<void>) => response.data));
    };

    public upload = (file: File): Observable<any> => {
        const formData: FormData = new FormData();
        formData.append("file", file as Blob);
        return this.httpObservable
            .post<any>(kebabCase(nameof(this.upload)), formData)
            .pipe(map((response: AxiosResponse<any>) => response.data));
    };

    public publish = (publishAt: Moment, homeControllerIds: number[], version: string, homeControllerTypeId: any): Observable<Firmware> => {
        return this.httpObservable
            .post<Firmware>(kebabCase(nameof(this.publish)), { publishAt, homeControllerIds , version, homeControllerTypeId})
            .pipe(map((response: AxiosResponse<Firmware>) => response.data));
    };

    public integratePublish = (publishAt: Moment, homeControllerIds: number[], version: string, homeControllerTypeId: any): Observable<Firmware> => {
        return this.httpObservable
            .post<Firmware>(kebabCase(nameof(this.integratePublish)), { publishAt, homeControllerIds , version, homeControllerTypeId})
            .pipe(map((response: AxiosResponse<Firmware>) => response.data));
    };

    public singleListDormitory = (): Observable<Dormitory[]> => {
        return this.httpObservable
            .post<Dormitory[]>(kebabCase(nameof(this.singleListDormitory)), new DormitoryFilter())
            .pipe(map((response: AxiosResponse<Dormitory[]>) => response.data));
    };

    public singleListHomeController = (): Observable<HomeController[]> => {
        return this.httpObservable
            .post<HomeController[]>(kebabCase(nameof(this.singleListHomeController)), new HomeControllerFilter())
            .pipe(map((response: AxiosResponse<HomeController[]>) => response.data));
    };

    public listHomeController = (): Observable<HomeController[]> => {
        return this.httpObservable
            .post<HomeController[]>(kebabCase(nameof(this.listHomeController)), new HomeControllerFilter())
            .pipe(map((response: AxiosResponse<HomeController[]>) => response.data));
    };

}

export const firmwareRepository = new FirmwareRepository();
