// For Firmwareintegrate
export enum IntegrateStatusEnum{
    Uninstalled,
    Installed
}


//for authen

export enum UserRoleEnum{
    Admin = 1,
    Operate = 2,
    Supporter = 3,
    User = 4
}