import { join } from "path";

export const ROOT_ROUTE: string = process.env.PUBLIC_URL;

export const LOGIN_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/login"
  : "/login"; // login route, dont delete
export const FORBIDENT_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/403"
  : "/403"; // forbident route, add project prefix if necessary
export const NOT_FOUND_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/404"
  : "/404"; // notFounded route, add project prefix if necessary

export const DASHBOARD_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/dashboard"
  : "/dashboard";
export const DASHBOARD_MASTER_ROUTE: string = join(DASHBOARD_ROUTE, "default");

export const APP_USER_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/app-user"
  : "/app-user";
export const APP_USER_MASTER_ROUTE: string = join(
  APP_USER_ROUTE,
  "app-user-master"
);
export const APP_USER_DETAIL_ROUTE: string = join(
  APP_USER_ROUTE,
  "app-user-detail"
);

export const TICKET_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/ticket"
  : "/ticket";
export const TICKET_MASTER_ROUTE: string = join(TICKET_ROUTE, "ticket-master");
export const TICKET_DETAIL_ROUTE: string = join(TICKET_ROUTE, "ticket-detail");

export const CRM_ROUTE: string = ROOT_ROUTE ? ROOT_ROUTE + "/crm" : "/crm";
export const CRM_MASTER_ROUTE: string = join(CRM_ROUTE, "crm-master");
export const CRM_DETAIL_ROUTE: string = join(CRM_ROUTE, "crm-detail");

export const CATEGORY_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/category"
  : "/category";
export const CATEGORY_MASTER_ROUTE: string = join(
  CATEGORY_ROUTE,
  "category-master"
);

export const CERTIFICATION_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/certification"
  : "/certification";
export const CERTIFICATION_MASTER_ROUTE: string = join(
  CERTIFICATION_ROUTE,
  "certification-master"
);

export const DATA_TYPE_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/data-type"
  : "/data-type";
export const DATA_TYPE_MASTER_ROUTE: string = join(
  DATA_TYPE_ROUTE,
  "data-type-master"
);
export const DATA_TYPE_DETAIL_ROUTE: string = join(
  DATA_TYPE_ROUTE,
  "data-type-detail"
);

export const DEVICE_ATTRIBUTE_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/device-attribute"
  : "/device-attribute";
export const DEVICE_ATTRIBUTE_MASTER_ROUTE: string = join(
  DEVICE_ATTRIBUTE_ROUTE,
  "device-attribute-master"
);
export const DEVICE_ATTRIBUTE_DETAIL_ROUTE: string = join(
  DEVICE_ATTRIBUTE_ROUTE,
  "device-attribute-detail"
);

export const DEVICE_TYPE_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/device-type"
  : "/device-type";
export const DEVICE_TYPE_MASTER_ROUTE: string = join(
  DEVICE_TYPE_ROUTE,
  "device-type-master"
);

export const END_USER_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/end-user"
  : "/end-user";
export const END_USER_MASTER_ROUTE: string = join(
  END_USER_ROUTE,
  "end-user-master"
);
export const END_USER_PREVIEW_ROUTE: string = join(
  END_USER_ROUTE,
  "end-user-preview"
);
export const END_USER_DETAIL_ROUTE: string = join(
  END_USER_ROUTE,
  "end-user-detail"
);

export const ICON_ROUTE: string = ROOT_ROUTE ? ROOT_ROUTE + "/icon" : "/icon";
export const ICON_MASTER_ROUTE: string = join(ICON_ROUTE, "icon-master");

export const DEVICE_ROUTE: string = ROOT_ROUTE ? ROOT_ROUTE + "/device" : "/device";

export const DEVICE_MASTER_ROUTE: string = join(DEVICE_ROUTE, "device-master");

export const DEVICE_DETAIL_ROUTE: string =  join(DEVICE_ROUTE, "device-detail");

export const DORMITORY_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/dormitory"
  : "/dormitory";
export const DORMITORY_MASTER_ROUTE: string = join(
  DORMITORY_ROUTE,
  "dormitory-master"
);
export const DORMITORY_DETAIL_ROUTE: string = join(
  DORMITORY_ROUTE,
  "dormitory-detail"
);

export const ROOM_ROUTE: string = ROOT_ROUTE ? ROOT_ROUTE + "/room" : "/room";
export const ROOM_DETAIL_ROUTE: string = join(ROOM_ROUTE, "room-detail");

export const HOME_CONTROLLER_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/home-controller"
  : "/home-controller";
export const HOME_CONTROLLER_MASTER_ROUTE: string = join(
  HOME_CONTROLLER_ROUTE,
  "home-controller-master"
);
export const HOME_CONTROLLER_DETAIL_ROUTE: string = join(
  HOME_CONTROLLER_ROUTE,
  "home-controller-detail"
);

export const FIRMWARE_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/firmware"
  : "/firmware";
export const FIRMWARE_MASTER_ROUTE: string = join(
  FIRMWARE_ROUTE,
  "firmware-master"
);

export const DEVICE_ACTIVITY_LOG_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/device-activity-log"
  : "/device-activity-log";
export const DEVICE_ACTIVITY_LOG_MASTER_ROUTE: string = join(
  DEVICE_ACTIVITY_LOG_ROUTE,
  "device-activity-log-master"
);

export const GROUP_ACTIVITY_LOG_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/group-activity-log"
  : "/group-activity-log";
export const GROUP_ACTIVITY_LOG_MASTER_ROUTE: string = join(
  GROUP_ACTIVITY_LOG_ROUTE,
  "group-activity-log-master"
);

export const SCENE_ACTIVITY_LOG_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/scene-activity-log"
  : "/scene-activity-log";
export const SCENE_ACTIVITY_LOG_MASTER_ROUTE: string = join(
  SCENE_ACTIVITY_LOG_ROUTE,
  "scene-activity-log-master"
);

export const HCL_EVENT_LOG_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/hcl-event-log"
  : "/hcl-event-log";
export const HCL_EVENT_LOG_MASTER_ROUTE: string = join(
  HCL_EVENT_LOG_ROUTE,
  "hcl-event-log-master"
);

export const RULE_ACTIVATION_EVENT_LOG_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/rule-activation-event-log"
  : "/rule-activation-event-log";
export const RULE_ACTIVATION_EVENT_LOG_MASTER_ROUTE: string = join(
  RULE_ACTIVATION_EVENT_LOG_ROUTE,
  "rule-activation-event-log-master"
);

export const RESOURCE_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/resource"
  : "/resource";
export const RESOURCE_MASTER_ROUTE: string = join(
  RESOURCE_ROUTE,
  "resource-master"
);
export const DEVICE_LIST_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/device"
  : "/device";
