import { Card, Col, Row, Select } from "antd";
import { BarChart } from "components/Charts/BarChart/BarChart";
import { DonutChart } from "components/Charts/DonutChart/DonutChart";
import { TICKET_MASTER_ROUTE } from "config/route-consts";
import { Dashboard } from "models/Dashboard";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { dashboardRepository } from "repositories/dashboard-repository";
import "./DashboardMaster.scss";
import OverviewInfo from "./OverviewInfo";
import RecentEndUsers from "./RecentEndUsers";
import RecentTickets from "./RecentTickets";




const { Option } = Select;

export default function DashboardMaster() {
  const [filter, setFilter] = useState<Dashboard>({
    time: 1,
    type: 1
  });
  const history = useHistory();

  const handleGoTicket = (_event: any) => {
    history.push(TICKET_MASTER_ROUTE);
  };

  return (
    <div className="page page__master dashboard-master__container">
      <div className="page__header d-flex justify-content-between">
        <div className="header-logo">
          <div className="splatter"></div>
          <h6 style={{color: '#2e499c'}} className="header-title">Chào mừng đến</h6>
          <h4 style={{color:'#2e499c'}} className="header-title font-weight-bold">Rạng Đông Portal</h4>
        </div>
        <div className="d-flex">
          <div>
            <h6>Dữ liệu</h6>
            <Select
              defaultValue={1}
              style={{ width: 120, borderStyle:'solid', borderWidth:1, borderRadius: 4, marginRight: 8}}
              onChange={(val) => setFilter({...filter, time: val})}
              bordered={false}
            >
              <Option value={1}>Tháng</Option>
              <Option value={2}>Quý</Option>
              <Option value={3}>Năm</Option>
            </Select>
          </div>
          <div>
            <h6>Hiển thị</h6>
            <Select
              defaultValue={1}
              style={{ width: 150, borderStyle:'solid', borderWidth:1, borderRadius: 4}}
              onChange={(val) => setFilter({...filter, type: val})}
              bordered={false}
              >
              <Option value={1}>Thống kê</Option>
              <Option value={2}>Tăng trưởng</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="page__body">
        <Row  gutter={16}
              className="d-flex"
        >
          <Col span={17}>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)" }}>
          <OverviewInfo
                filter={filter}
                title="Khách hàng"
                getData={dashboardRepository.overviewEndUser}
                icon="tio-account_circle"
                color="#2f80ed"
              />
              <OverviewInfo
                filter={filter}
                title="Nhà"
                getData={dashboardRepository.overviewDormitory}
                icon="tio-home_outlined"
                color="#9878"
              />
              <OverviewInfo
                filter={filter}
                title="HC"
                getData={dashboardRepository.overviewHomeController}
                icon="tio-speaker"
                color="#f2994a"

              />
              <OverviewInfo
                filter={filter}
                title="Thiết bị"
                getData={dashboardRepository.overviewDevice}
                icon="tio-plug_outlined"
                color="#219653"
              />
              <OverviewInfo
                filter={filter}
                title="Vấn đề"
                getData={dashboardRepository.overviewTicket}
                icon="tio-ticket"
                color="#2f8098"
              />
            </div>
            <Row
              gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}
              className="mt-4 dashboard-master__recent"
            >
              <Col span={24}>
                <Card title={<strong>Thống kê</strong>}>
                  <BarChart filter={filter} loadData={dashboardRepository.overview} height={350} colors={['#BFDBFF', '#97C1FF','#6EA6FF','#3682FD','#416CF0']}/>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex" span={7} >
            <Card className="dashboard-master__ticket" title={<strong>Vấn đề</strong>} style={{width:'100%'}}>
              <DonutChart filter={filter} loadData={dashboardRepository.statisticTicket} height={350}/>
              <Row className="d-flex align-items-center justify-content-center" >
                <span onClick={handleGoTicket}>Xem thêm</span>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row
          gutter={16}
          className="mt-4 dashboard-master__recent"
        >
          <Col span={12}>
            <Card title={<strong>Homecontroller</strong>}>
              <BarChart filter={filter} loadData={dashboardRepository.statisticHomeController} stacked height={350} horizontal/>
            </Card>
          </Col>          
          <Col span={12}>
            <Card title={<strong>Thiết bị</strong>}>
              <BarChart filter={filter} loadData={dashboardRepository.statisticDevice} height={350} horizontal     />
            </Card>
          </Col>    
        </Row>
        <Row
          gutter={16}
          className="mt-4 dashboard-master__recent"
        >
          <Col span={8}>
            <Card
              title={<strong>Khách hàng mới</strong>}
              bordered={false}
            >
              <RecentEndUsers getData={dashboardRepository.listEndUser} filter={filter}/>
            </Card>
          </Col>
          <Col span={16}>
            <Card
              title={<strong>Khiếu nại mới</strong>}
              bordered={false}
            >
              <RecentTickets filter={filter} getData={dashboardRepository.listTicket} />
            </Card>
          </Col>
        </Row>
       
      </div>
    </div>
  );
}
