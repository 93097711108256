import { Model } from "@react3l/react3l/core";
import { Activity } from "models/Activity";
import { Dormitory } from "models/Dormitory";
import { EndUserProfile } from "models/EndUserProfile";
import { EndUserRole } from "models/EndUserRole";
import { HomeController } from "models/HomeController";
import { Room } from "models/Room";
import { Scene } from "models/Scene";
import { Status } from "models/Status";
import { Moment } from "moment";

export class Sex extends Model {
  id: string;
  code: string;
  name: string;
}

export class VerificationType extends Model {
  id: string;
  code: string;
  name: string;
}

export class EndUser extends Model {
  public id?: string;

  public avatar?: string;

  public userName?: string;

  public code?: string;

  public displayName?: string;

  public password?: string;

  public rowId?: string;

  public tickets?: any[];

  public phoneNumber?: string;

  public email?: string;

  public sexId?: number;

  public sex?: Sex;

  public statusId?: number;

  public verificationStatusId?: number;

  public status?: Status;

  public verificationStatus?: Status;

  public errors?: any;

  public rooms?: Room[];

  public scenes?: Scene[];

  public dormitories?: Dormitory[];

  public homeControllers?: HomeController[];

  public endUserProfiles?: EndUserProfile[];

  public endUserRole?: EndUserRole;

  public activityLogs?: Activity[];

  public birthday?: Moment;

  public createdAt?: Moment;
}
