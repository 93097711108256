/* begin general import */
import { Model } from "@react3l/react3l/core/model";
import Modal from "components/Utility/Modal/Modal";
import { TFunction } from "i18next";
import React from "react";
/* end general import */
/* begin individual import */
/* end individual import */
import { Col, Row, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import { Device } from "models/Device";
import { DeviceType } from "models/DeviceType";
import './HomecontrollerPreview.scss';

interface DevicePreviewProps<T extends Model> {
  previewModel?: T;
  isOpenPreview?: boolean;
  isLoadingPreview?: boolean;
  handleClosePreview?: () => void;
  handleGoDetail?: (id: number) => () => void;
  translate?: TFunction;
}
function HomeControllerPreview(props: DevicePreviewProps<Device>) {
  const {
    previewModel,
    isOpenPreview,
    isLoadingPreview,
    handleClosePreview,
    // handleGoDetail,
    // translate
  } = props;

  const deviceColumns: ColumnProps<Device>[] = [
    {
      title: <div className="gradient-text text-upper">Tên/MAC</div>,
      key: "name",
      dataIndex: "name",
      width: 200,
      render(name: string) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames("cell-master__first-row", {
                "first-row--ellipsis": true,
              })}
            >
              {name}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Nhóm thiết bị</div>,
      key: "deviceType",
      dataIndex: "deviceType",
      width: 200,
      render(deviceType: DeviceType) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames("cell-master__first-row", {
                "first-row--ellipsis": true,
              })}
            >
              {deviceType?.name}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">ID Thiết Bị</div>,
      key: "id",
      dataIndex: "id",
      width: 400,
      render(id: string) {
        return (
          <div className="ant-cell-master__container">
            <div className="cell-master__first-row">{id}</div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">FW Version</div>,
      key: "firmwareVersion",
      dataIndex: "firmwareVersion",
      width: 150,
      render(firmwareVersion: string) {
        return (
          <div className="ant-cell-master__container">
            <div className="cell-master__first-row">{firmwareVersion}</div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Modal
        title={null}
        visible={isOpenPreview}
        handleCancel={handleClosePreview}
        width={1200}
        visibleFooter={false}
      >
        {isLoadingPreview ? (
          <div className="loading-block">
            <img src="/assets/images/spinner.svg" alt="Loading..." />
          </div>
        ) : (
          <div className="preview__containter">
            <div className="preview__left-side">
              <div className="preview__header">
                <div className="preview__vertical-bar"></div>
                <div className="preview__header-info">
                  <div className="preview__header-text">
                    <span className="preview__header-title">
                    </span>
                  </div>
                </div>
              </div>
              <div className="preview__body">
                <div className="preview__content">

                  <Col>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col className="gutter-row" span={24}>
                        <div className="table__container w-100">
                          <div className="table__header w-100 d-flex justify-content-between">
                            <span className="card-title">Devices</span>
                          </div>
                          <div className="table__content">
                            <Table
                              rowKey={"id"}
                              columns={deviceColumns}
                              pagination={false}
                              dataSource={previewModel ? [previewModel] : []}
                              rowSelection={null}
                              scroll={{ y: 400, x: "max-content" }}
                            ></Table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                </div>
              </div>
              <div className="preview__footer"></div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
export default HomeControllerPreview;
