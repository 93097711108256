import { Notification } from "models/Notification";

export interface INotificationReducerState {
    notification?: Notification;
}

export enum NotificationReducerActionEnum {
    CHANGE_TITLE,
    CHANGE_CONTENT,
    CHANGE_IDS,
    CHANGE_IMAGE,
    CHANGE_LINK,
    RESET_NOTIFICATION
}

export interface INotificationReducerAction {
    type: NotificationReducerActionEnum,
    notification?: Notification
}

export function notificationReducer (
    state: INotificationReducerState,
    action: INotificationReducerAction
): INotificationReducerState{
    switch (action.type){
        case NotificationReducerActionEnum.CHANGE_TITLE:
            return {
                ...state.notification,
                notification: {...state.notification, title: action.notification?.title}
            };
        case NotificationReducerActionEnum.CHANGE_CONTENT:
            return {
                    ...state.notification,
                    notification: {...state.notification, content: action.notification?.content}
            };
        case NotificationReducerActionEnum.CHANGE_IDS:
            return {
                    ...state.notification,
                    notification: {...state.notification, ids: action.notification?.ids}
            };
        case NotificationReducerActionEnum.CHANGE_IMAGE:
            return {
                    ...state.notification,
                    notification: {...state.notification, imageUrl: action.notification?.imageUrl}
            };
        case NotificationReducerActionEnum.CHANGE_LINK:
            return {
                    ...state.notification,
                    notification: {...state.notification, link: action.notification?.link}
            };
        case NotificationReducerActionEnum.RESET_NOTIFICATION:
            return {
                ...state.notification,
                notification:{
                    ...state.notification,
                    title:'',
                    content:'',
                    imageUrl:'',
                    link:''
                }
            };
        default: 
        return state;
    }
}