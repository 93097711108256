/* begin general import */
import { Model } from "@react3l/react3l/core/model";
import { Descriptions } from "antd";
import Modal from "components/Utility/Modal/Modal";
import { TFunction } from "i18next";
import moment from "moment";
import React from "react";
/* end general import */
/* begin individual import */
import { Firmware } from "models/Firmware";
/* end individual import */
import { Col, Row, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import { HomeController } from "models/HomeController";
import { commonWebService } from "services/common-web-service";
import './FirmwareMaster.scss';

interface FirmwarePreviewProps<T extends Model> {
  previewModel?: T;
  isOpenPreview?: boolean;
  isLoadingPreview?: boolean;
  handleClosePreview?: () => void;
  handleGoDetail?: (id: number) => () => void;
  translate?: TFunction;
}
function FirmwarePreview(props: FirmwarePreviewProps<Firmware>) {
  const {
    previewModel,
    isOpenPreview,
    isLoadingPreview,
    handleClosePreview,
    handleGoDetail,
    translate
  } = props;

  const homeControllerColums: ColumnProps<HomeController>[] = [
    {
      title: <div className="gradient-text text-upper">HC/MAC</div>,
      key: "name",
      dataIndex: "name",
      width: 200,
      render(name: string, hc: HomeController) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames(
                "cell-master__first-row",
                "first-row--link",
                {
                  "first-row--ellipsis": true,
                }
              )}
            >
              {name}
            </div>
            <div className="cell-master__second-row">{hc.macAddress}</div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">IP của HC</div>,
      key: "ipAddress",
      dataIndex: "ipAddress",
      width: 200,
      render(ipAddress: string) {
        return (
          <div className="ant-cell-master__container">
            <div
              className={classNames("cell-master__first-row", {
                "first-row--ellipsis": true,
              })}
            >
              {ipAddress}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="gradient-text text-upper">Created At</div>,
      key: "createdAt",
      dataIndex: "createdAt",
      width: 200,
      render(createdAt: string) {
        return (
          <div className="ant-cell-master__container">
            <div className="cell-master__first-row">{moment(createdAt).format('DD/MM/YYYY')}</div>
          </div>
        );
      },
    },

  ];
  return (
    <>
      <Modal
        title={null}
        visible={isOpenPreview}
        handleCancel={handleClosePreview}
        width={1200}
        visibleFooter={false}
      >
        {isLoadingPreview ? (
          <div className="loading-block">
            <img src="/assets/images/spinner.svg" alt="Loading..." />
          </div>
        ) : (
          <div className="preview__containter">
            <div className="preview__left-side">
              <div className="preview__header">
                <div className="preview__vertical-bar"></div>
                <div className="preview__header-info">
                  <div className="preview__header-text">
                    <span className="preview__header-title">
                      {previewModel.name}
                    </span>
                    <span className="preview__header-date">
                      {translate("firmwares.createdAt")}{" "}
                      {previewModel.createdAt
                        ? moment(previewModel.createdAt).format("DD/MM/YYYY")
                        : null}
                    </span>
                  </div>
                  <button
                    className="btn gradient-btn-icon ant-tooltip-open"
                    onClick={handleGoDetail(previewModel.id)}
                  >
                    <i className="tio-edit"></i>
                  </button>
                </div>
              </div>
              <div className="preview__body">
                <div className="preview__content">
                  <Descriptions title={previewModel.name} column={2}>
                    <Descriptions.Item label={translate("firmwares.name")}>
                      <span className="gradient-text">{previewModel.name}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label={translate("firmwares.version")}>
                      <span className="gradient-text">
                        {previewModel.version}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label={translate("firmwares.checksum")}>
                      <span className="gradient-text">
                        {previewModel.checksum}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label={translate("firmwares.homeControllerTypeId")}>
                      <span className="gradient-text">
                        {previewModel.homeControllerTypeId}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label={"File"}>
                      <div className="file-name d-flex align-items-center mt-0">
                        <i className="tio-attachment_diagonal mr-3"></i>{" "}
                        <a
                          href={previewModel.url}
                          download={"Firmware_" + previewModel.name}
                        >
                          {"Firmware_" + previewModel.name}
                        </a>
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions layout="vertical">
                    <Descriptions.Item label="Mô tả">
                      <div className="firmware__description" dangerouslySetInnerHTML={commonWebService.renderMarkdown(previewModel.description)}></div>
                    </Descriptions.Item>
                  </Descriptions>

                  <Col>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col className="gutter-row" span={24}>
                        <div className="table__container w-100">
                          <div className="table__header w-100 d-flex justify-content-between">
                            <span className="card-title">HomeControllers</span>
                            <span className="card-title">
                              Tổng: {previewModel?.homeControllers?.length}
                            </span>
                          </div>
                          <div className="table__content">
                            <Table
                              rowKey={"id"}
                              columns={homeControllerColums}
                              pagination={false}
                              dataSource={previewModel.homeControllers}
                              rowSelection={null}
                              scroll={{ y: 400, x: "max-content" }}
                            ></Table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                </div>
              </div>
              <div className="preview__footer"></div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
export default FirmwarePreview;
