import { AxiosResponse } from "axios";
import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";

import { API_DORMITORY_PREFIX } from "config/api-consts";
import { EndUser, EndUserFilter } from "models/EndUser";
import { Supporter } from "models/Supporter";
import { Dormitory, DormitoryFilter } from "models/Dormitory";
import { DurationFilter, Duration } from "models/Duration";
import { Notification } from "models/Notification";

export class DormitoryRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_DORMITORY_PREFIX);
  }

  public count = (dormFilter?: DormitoryFilter): Observable<number> => {
    return this.httpObservable
      .post<number>(kebabCase(nameof(this.count)), dormFilter)
      .pipe(map((response: AxiosResponse<number>) => response.data));
  };

  public list = (
    dormFilter?: DormitoryFilter
  ): Observable<Dormitory[]> => {
    return this.httpObservable
      .post<Dormitory[]>(kebabCase(nameof(this.list)), dormFilter)
      .pipe(map((response: AxiosResponse<Dormitory[]>) => response.data));
  };

  public get = (id?: number): Observable<Dormitory> => {
    return this.httpObservable
      .post<Dormitory>(kebabCase(nameof(this.get)), { id })
      .pipe(map((response: AxiosResponse<Dormitory>) => response.data));
  };

  public addSupporter = (supporter?: Supporter): Observable<any> => {
    return this.httpObservable
      .post<any>(kebabCase(nameof(this.addSupporter)), supporter)
      .pipe(map((response: AxiosResponse<any>) => response.data));
  };

  public singleListDuration = (
    durationFilter: DurationFilter
  ): Observable<Duration[]> => {
    return this.httpObservable
      .post<Duration[]>(
        kebabCase(nameof(this.singleListDuration)),
        durationFilter
      )
      .pipe(map((response: AxiosResponse<Duration[]>) => response.data));
  };

  public singleListEndUser = (
    endUserFilter: EndUserFilter
  ): Observable<EndUser[]> => {
    return this.httpObservable
      .post<EndUser[]>(kebabCase(nameof(this.singleListEndUser)), endUserFilter)
      .pipe(map((response: AxiosResponse<EndUser[]>) => response.data));
  };
  public sendNotification = (notification: Notification): Observable<boolean> => {
    return this.httpObservable
    .post<boolean>(kebabCase(nameof(this.sendNotification)), notification)
    .pipe(map((response: AxiosResponse<boolean>) => response.data));
  }
}

export const dormitoryRepository = new DormitoryRepository();
