/* begin general import */
import { Card, Col, Dropdown, Menu, Row } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import classNames from "classnames";
import InputSearch from "components/Utility/InputSearch/InputSearch";
import Pagination from "components/Utility/Pagination/Pagination";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import masterService from "services/pages/master-service";
import { getAntOrderType } from "services/table-service";
import nameof from "ts-nameof.macro";
import "./DormitoryMaster.scss";
/* end general import */

/* begin filter import */
import { StringFilter } from "@react3l/advanced-filters";
import AdvanceStringFilter from "components/Utility/AdvanceFilter/AdvanceStringFilter/AdvanceStringFilter";
/* end filter import */

/* begin individual import */
import SendNotificationModal from "components/Molecules/NotificationForm/NotificationForm";
import { SendNotificationTypeEnum } from "config/consts";
import { UserRoleEnum } from "config/enum";
import { DORMITORY_ROUTE } from "config/route-consts";
import { formatDate } from "helpers/date-time";
import { Dormitory, DormitoryFilter } from "models/Dormitory";
import { Moment } from "moment";
import { dormitoryRepository } from "repositories/dormitory-repository";
import { authService } from "services/auth-services";
import { sendNotificationServive } from "services/send-notification-service";
/* end individual import */

function DormitoryMaster() {
  const [translate] = useTranslation();
  const [showForm, setShowForm] = React.useState(false);

  const {userRole} = authService.useRole();

  const {
    list,
    total,
    loadingList,
    filter,
    toggle,
    handleChangeFilter,
    handleUpdateNewFilter,
    handleToggleSearch,
    handleTableChange,
    handlePagination,
    handleGoDetail,
    rowSelection
  } = masterService.useMaster<Dormitory, DormitoryFilter>(
    DormitoryFilter,
    DORMITORY_ROUTE,
    dormitoryRepository.list,
    dormitoryRepository.count
  );
  const {
    notification,
    handleChangeTitle,
    handleChangeContent,
    handleChangeIds,
    handleChangeImageUrl,
    handleChangeLink,
    handleSend
  } = sendNotificationServive.useNotificationForm(SendNotificationTypeEnum.Dormitory);
  const handleShowModal = React.useCallback(() => {
    setShowForm(true);
  }, []);
  const handleConfirm = React.useCallback(() => {
    handleSend().then((response) => {
      if(response){
        setShowForm(false);
      }
    });
  }, [handleSend]);
  const handleCancelModal = React.useCallback(() => {
    setShowForm(false);
  }, []);

  const _handleChangeFilter = React.useCallback(
    (value) => {
      const newFilter = { ...filter };
      newFilter["search"] = value;
      handleUpdateNewFilter(newFilter);
    },
    [filter, handleUpdateNewFilter]
  );

  const menuAction = React.useCallback(
    (id: number) => (
      <Menu>
        <Menu.Item key="1">
          <div className="ant-action-menu" onClick={handleGoDetail(id)}>
            {translate("general.actions.view")}
          </div>
        </Menu.Item>
      </Menu>
    ),
    [handleGoDetail, translate]
  );

  const columns: ColumnProps<Dormitory>[] = useMemo(
    () => [
      {
        title: <div className="text-center gradient-text">Tên nhà</div>,
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<Dormitory, DormitoryFilter>(
          filter,
          nameof(list[0].name)
        ),
        width: 200,
        render(name: string, hc: Dormitory) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row")}>{name}</div>
              <div className={classNames("cell-master__second-row")}>
                {hc.macAddress}
              </div>
            </div>
          );
        },
      },

      {
        title: <div className="text-center gradient-text">Địa chỉ</div>,
        key: nameof(list[0].address),
        dataIndex: nameof(list[0].address),
        sorter: true,
        sortOrder: getAntOrderType<Dormitory, DormitoryFilter>(
          filter,
          nameof(list[0].address)
        ),
        width: 400,
        render(name: string, hc: Dormitory) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row")}>{name}</div>
              <div className={classNames("cell-master__second-row")}>
                {hc.macAddress}
              </div>
            </div>
          );
        },
      },

      {
        title: <div className="text-center gradient-text">Ngày tạo</div>,
        key: nameof(list[0].createdAt),
        dataIndex: nameof(list[0].createdAt),
        sorter: true,
        sortOrder: getAntOrderType<Dormitory, DormitoryFilter>(
          filter,
          nameof(list[0].createdAt)
        ),
        width: 150,
        align: "center",
        render(createdAt: Moment) {
          return (
            <div className="ant-cell-master__container">
              <div className={classNames("cell-master__first-row")}>
                {formatDate(createdAt)}
              </div>
            </div>
          );
        },
      },

      {
        title: (
          <div className="text-center gradient-text">
            {translate("general.actions.action")}
          </div>
        ),
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 100,
        align: "center",
        render(id: number) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id)}
                trigger={["click"]}
                placement="bottomCenter"
                arrow
              >
                <span className="action__dots">...</span>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, list, filter, menuAction]
  );

  const filterChildren = useMemo(
    () => (
      <Row className="mt-4">
        <Col lg={4} className="pr-4">
          <label className="label">{translate("dormitory.name")}</label>
          <AdvanceStringFilter
            isMaterial={true}
            value={filter[nameof(list[0].name)]["contain"]}
            onEnter={handleChangeFilter(
              nameof(list[0].name),
              "contain" as any,
              StringFilter
            )}
            placeHolder="Tìm kiếm..."
          />
        </Col>
      </Row>
    ),
    [filter, handleChangeFilter, list, translate]
  );

  return (
    <>
      <div className="page page__master dormitory__container">
        <div className="page__header d-flex align-items-center justify-content-between">
          <div className="page__title">
            {translate("dormitory.master.title")}
          </div>
        </div>
        <div className="page__search">
          <Card bordered={false}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex w-50">
                <div className="w70">
                  <InputSearch
                    value={filter["search"]}
                    onChange={_handleChangeFilter}
                    placeHolder="Tìm kiếm"
                  />
                </div>
                <button
                  className={classNames(
                    "btn component__btn-toggle ml-2",
                    toggle === true ? "component__btn-toggle-active" : ""
                  )}
                  onClick={handleToggleSearch}
                >
                  <i className="tio-tune_horizontal mr-2"></i>
                  <span className="component_btn-text">
                    {translate("general.button.advance")}
                  </span>
                </button>
              </div>
              {userRole.includes(UserRoleEnum.Admin||UserRoleEnum.Operate) && (
                <div className="d-flex">
                  <button
                    className="btn component__btn-primary component__btn-primary"
                    onClick={handleShowModal}
                  >
                    <i className="tio-send_outlined mr-2"></i>
                    <span className="component_btn-text">Gửi thông báo</span>
                  </button>
                </div>
              )}
            </div>
            <CSSTransition
              in={toggle}
              timeout={100}
              classNames={"show"}
              unmountOnExit
            >
              {filterChildren}
            </CSSTransition>
          </Card>
        </div >
        <div className="page__master-table custom-scrollbar pb-4">
          <Card bordered={false}>
            <Table
              rowKey={nameof(list[0].id)}
              columns={columns}
              pagination={false}
              dataSource={list}
              loading={loadingList}
              onChange={handleTableChange}
              rowSelection={{
                onChange(selectedRowKeys: any) {
                  rowSelection.onChange(selectedRowKeys);
                  handleChangeIds(selectedRowKeys);
                },
                type: 'checkbox'
              }}
              scroll={{ x: "max-content" }}
              title={() => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="action__result">{total} Kết quả</span>
                    <div className="flex-shrink-1 d-flex align-items-center">
                      <Pagination
                        skip={filter.skip}
                        take={filter.take}
                        total={total}
                        onChange={handlePagination}
                        style={{ margin: "10px" }}
                      />
                    </div>
                  </div>
                </>
              )}
            />
          </Card>
        </div>
        <SendNotificationModal
          isOpen={showForm}
          notification={notification}
          confirm={handleConfirm}
          cancel={handleCancelModal}
          onChangeTitle={handleChangeTitle}
          onChangeContent={handleChangeContent}
          onChangeImage={handleChangeImageUrl}
          onChangeLink={handleChangeLink}
        />
      </div >
    </>
  );
}

export default DormitoryMaster;
