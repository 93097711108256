import { Model } from "@react3l/react3l/core";
import { DeviceType } from "models/DeviceType";
import { HomeController } from "models/HomeController";

export class Device extends Model {
  public id?: string;
  public name?: string;
  public deviceTypeId?: number;
  public deviceType?: DeviceType;
  public firmwareVersion?: string;
  public parentId?: string;
  public roomId?: string;
  public dormitoryId?: string;
  public categoryId?: number;
  public deviceClassId?: number | null;
  public statusId?: number;
  public homeControllerId?: string;
  public createdAt?: Date;
  public updatedAt?: Date;
  public deletedAt?: Date;
  public tuyaIrRemoteId?: string;
  public macAddress?: string;
  public ipAddress?: string;
  public iconId?: number;
  public homeController?: HomeController;
  public rowId?: string;
}
