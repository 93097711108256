export interface IDeviceType{
  code: number,
  typeName: string,
  categoryId: number
}
export const deviceTypes: IDeviceType[] = [
  {code: 12001, typeName: 'Downlight SMT', categoryId: 12},
  {code: 12002, typeName: 'Downlight COB', categoryId: 12},
  {code: 12003, typeName: 'Downlight trang trí', categoryId: 12},
  {code: 12004, typeName: 'Downlight trang trí', categoryId: 12},
  {code: 12005, typeName: 'Panel tròn', categoryId: 12},
  {code: 12006, typeName: 'Panel', categoryId: 12},
  {code: 12007, typeName: 'Ốp trần', categoryId: 12},
  {code: 12008, typeName: 'Đèn gắn tường', categoryId: 12},
  {code: 12009, typeName: 'Đèn gắn tường', categoryId: 12},
  {code: 12010, typeName: 'Tracklight', categoryId: 12},
  {code: 12011, typeName: 'Đèn thả trần', categoryId: 12},
  {code: 12012, typeName: 'Đèn chiếu gương', categoryId: 12},
  {code: 12013, typeName: 'LED dây sáng trắng', categoryId: 12},
  {code: 12014, typeName: 'Đèn tube', categoryId: 12},
  {code: 12015, typeName: 'Đèn bàn', categoryId: 12},
  {code: 12016, typeName: 'Đèn trang trí', categoryId: 12},
  {
    code: 12020,
    typeName: 'Đèn ray LED thanh x/g đổi màu RLT03.BLE.CW 130/6W 48V',
    categoryId: 12,
  },
  {
    code: 12021,
    typeName: 'Đèn ray LED thanh đổi màu RLT02.BLE.CW 370/10W 48V',
    categoryId: 12,
  },
  {
    code: 12022,
    typeName: 'Đèn ray LED thanh đổi màu RTL02.BLE.CW 670/20W 48V',
    categoryId: 12,
  },
  {
    code: 12023,
    typeName: 'Đèn ray LED thanh đổi màu RLT01.BLE.CW 330/10W 48V',
    categoryId: 12,
  },
  {
    code: 12024,
    typeName: 'Đèn LED Tracklight đôi đổi màu TRL08.BLE.CW 20W 48V',
    categoryId: 12,
  },
  {
    code: 1202,
    typeName: 'Đèn LED Tracklight đổi màu TRL08.BLE.CW 10W 48V',
    categoryId: 12,
  },
  {
    code: 12026,
    typeName: 'Đèn ray LED thanh x/g đổi màu RLT03.BLE.CW 240/12W 48V',
    categoryId: 12,
  },
  {
    code: 12027,
    typeName: 'Ốp trần',
    categoryId: 12,
  },
  {
    code: 12028,
    typeName: 'Đèn LED Downlight nổi trần đổi màu NT01.BLE 150/25W',
    categoryId: 12,
  },
  {
    code: 12029,
    typeName: 'Đèn LED Tracklight đổi màu',
    categoryId: 12,
  },
  {code: 13001, typeName: 'LED dây màu RGB', categoryId: 13},
  {code: 14001, typeName: 'LED dây màu', categoryId: 14},
  {code: 14002, typeName: 'LED bulb màu', categoryId: 14},
  {code: 14003, typeName: 'Downlight màu', categoryId: 14},
  {code: 15001, typeName: 'Ốp trần có loa', categoryId: 15},

  {code: 21001, typeName: 'Công tắc chuyển mạch ON/OFF', categoryId: 21},
  {code: 21002, typeName: 'Công tắc chuyển mạch ON/OFF CT03', categoryId: 21},

  //Công tắc Rạng Đông (Không có RGB)
  {code: 22001, typeName: 'Công tắc 1 nút', categoryId: 22},
  {code: 22002, typeName: 'Công tắc 2 nút', categoryId: 22},
  {code: 22003, typeName: 'Công tắc 3 nút', categoryId: 22},
  {code: 22004, typeName: 'Công tắc 4 nút', categoryId: 22},

  {code: 22005, typeName: 'Công tắc BNL', categoryId: 22},
  {code: 22006, typeName: 'Công tắc rèm', categoryId: 22},

  //Công tắc Ralli RGB
  {code: 22012, typeName: 'Công tắc 1 nút', categoryId: 22},
  {code: 22013, typeName: 'Công tắc 2 nút', categoryId: 22},
  {code: 22014, typeName: 'Công tắc 3 nút', categoryId: 22},
  {code: 22015, typeName: 'Công tắc 4 nút', categoryId: 22},

  {code: 22016, typeName: 'Công tắc BNL', categoryId: 22},
  {code: 22017, typeName: 'Công tắc rèm', categoryId: 22},
  {code: 22018, typeName: 'Công tắc cửa cuốn', categoryId: 22},

  //Công tắc Ralli RGB Vuông
  {code: 22019, typeName: 'Công tắc 1 nút', categoryId: 22},
  {code: 22020, typeName: 'Công tắc 2 nút', categoryId: 22},
  {code: 22021, typeName: 'Công tắc 3 nút', categoryId: 22},
  {code: 22022, typeName: 'Công tắc 4 nút', categoryId: 22},
  {code: 22024, typeName: 'Công tắc rèm', categoryId: 22},

  {code: 22025, typeName: 'Công tắc cửa cuốn', categoryId: 22},

  //Các loại Công tắc Ralli RGB (mẫu mới)
  {code: 22026, typeName: 'Công tắc 1 nút', categoryId: 22},
  {code: 22027, typeName: 'Công tắc 1 nút vuông', categoryId: 22},
  {code: 22028, typeName: 'Công tắc 2 nút', categoryId: 22},
  {code: 22029, typeName: 'Công tắc 2 nút vuông', categoryId: 22},
  {code: 22030, typeName: 'Công tắc 3 nút', categoryId: 22},
  {code: 22031, typeName: 'Công tắc 3 nút vuông', categoryId: 22},
  {code: 22032, typeName: 'Công tắc 4 nút', categoryId: 22},
  {code: 22033, typeName: 'Công tắc 4 nút vuông', categoryId: 22},

  {code: 22034, typeName: 'Công tắc rèm', categoryId: 22},
  {code: 22035, typeName: 'Công tắc rèm vuông', categoryId: 22},
  {code: 22036, typeName: 'Công tắc cửa cuốn vuông', categoryId: 22},

  {code: 22037, typeName: 'Công tắc chuyển mạch 2 chiều 2 nút', categoryId: 22},
  {code: 22038, typeName: 'Công tắc chuyển mạch 2 chiều 3 nút', categoryId: 22},
  {code: 22039, typeName: 'Công tắc chuyển mạch 2 chiều 5 nút', categoryId: 22},
  {code: 22040, typeName: 'Công tắc cảm ứng BLE-Wifi 1 nút', categoryId: 22},
  {code: 22041, typeName: 'Công tắc cảm ứng BLE-Wifi 2 nút', categoryId: 22},
  {code: 22042, typeName: 'Công tắc cảm ứng BLE-Wifi 3 nút', categoryId: 22},
  {code: 22043, typeName: 'Công tắc cảm ứng BLE-Wifi 4 nút', categoryId: 22},
  {code: 22044, typeName: 'Công tắc cửa cuốn BLE-Wifi', categoryId: 22},
  {code: 22045, typeName: 'Công tắc cửa cuốn BLE-Wifi vuông', categoryId: 22},

  {code: 22046, typeName: 'Công tắc rèm BLE-Wifi', categoryId: 22},
  {code: 22047, typeName: 'Công tắc rèm BLE-Wifi vuông', categoryId: 22},

  {code: 22048, typeName: 'Công tắc BLE-Wifi 1 nút vuông', categoryId: 22},
  {code: 22049, typeName: 'Công tắc BLE-Wifi 2 nút vuông', categoryId: 22},
  {code: 22050, typeName: 'Công tắc BLE-Wifi 3 nút vuông', categoryId: 22},
  {code: 22051, typeName: 'Công tắc BLE-Wifi 4 nút vuông', categoryId: 22},

  {code: 23001, typeName: 'DKTX M2', categoryId: 23},
  {code: 23002, typeName: 'DKTX âm tường', categoryId: 23},
  {code: 23003 , typeName: 'Màn hình DKTX', categoryId: 23},
  {code: 23004 , typeName: 'DKTX M3', categoryId: 23},
  {code: 23005 , typeName: 'DKTX M4', categoryId: 23},
  {code: 23006 , typeName: 'DKTX âm tường', categoryId: 23},
  {code: 23007 , typeName: 'DKTX M3 V2', categoryId: 23},
  {code: 23008 , typeName: 'DKTX âm tường vuông', categoryId: 23},

  {code: 24001 , typeName: 'Công tắc cơ 1 nút', categoryId: 22},
  {code: 24002 , typeName: 'Công tắc cơ 2 nút', categoryId: 22},
  {code: 24003 , typeName: 'Công tắc cơ 3 nút', categoryId: 22},
  {code: 24004 , typeName: 'Công tắc cơ 4 nút', categoryId: 22},
  {code: 24005 , typeName: 'Công tắc cơ BNL', categoryId: 22},

  {code: 24006 , typeName: 'Công tắc cơ 1 nút', categoryId: 22},
  {code: 24007 , typeName: 'Công tắc cơ 2 nút', categoryId: 22},
  {code: 24008 , typeName: 'Công tắc cơ 3 nút', categoryId: 22},

  {code: 24009 , typeName: 'Công tắc cơ BLE-Wifi 1 nút', categoryId: 22},
  {code: 24010 , typeName: 'Công tắc cơ BLE-Wifi 2 nút', categoryId: 22},
  {code: 24011 , typeName: 'Công tắc cơ BLE-Wifi 3 nút', categoryId: 22},

  {code: 26001 , typeName: 'Ổ cắm đơn', categoryId: 26},
  {code: 26002 , typeName: 'Ổ cắm kéo dài', categoryId: 26},
  {code: 26003 , typeName: 'Ổ cắm công tắc chữ nhật', categoryId: 26},

  {code: 27001, typeName: 'Công tắc 2 chiều không dây 1 nút', categoryId: 27},
  {code: 27002, typeName: 'Công tắc 2 chiều không dây 2 nút', categoryId: 27},
  {code: 27003, typeName: 'Công tắc 2 chiều không dây 3 nút', categoryId: 27},
  {code:27006, typeName: 'Công tắc 2 chiều không dây 6 nút', categoryId: 27},

  {code: 31001, typeName: 'Cảm biến ánh sáng', categoryId: 31},
  {code: 31002, typeName: 'Cảm biến ánh sáng', categoryId: 31},

  {code: 32001, typeName: 'Cảm biến chuyển động', categoryId: 32},
  {code: 32002, typeName: 'Cảm biến chuyển động AC', categoryId: 32},
  {code: 32004, typeName: 'Cảm biến chuyển động âm trần', categoryId: 32},
  {code: 32006, typeName: 'Cảm biến chuyển động CB10', categoryId: 32},
  {code: 32007, typeName: 'Cảm biến chuyển động CB09', categoryId: 32},
  {code: 32008, typeName: 'Cảm biến hiện diện CB15.RAD', categoryId: 32},

  {code: 33001, typeName: 'Cảm biến khói', categoryId: 33},
  {code: 36001, typeName: 'Cảm biến cửa', categoryId: 36},
  {code: 36002, typeName: 'Cảm biến cửa', categoryId: 36},
  {code: 37001, typeName: 'Cảm biến bụi mịn', categoryId: 37},
  {code: 38001, typeName: 'Cảm biến nhiệt/ẩm', categoryId: 38},
  {code: 38002, typeName: 'Cảm biến nhiệt/ẩm không khí', categoryId: 38},
  {code: 38003, typeName: 'Cảm biến độ ẩm đất', categoryId: 38},

  {code: 39001, typeName: 'Cảm biến PH đất', categoryId: 39},
  {code: 39002, typeName: 'Cảm biến EC, nhiệt/ẩm đất', categoryId: 39},
  {code: 39003, typeName: 'Cảm biến PH nước', categoryId: 39},
  {code: 39004, typeName: 'Cảm biến EC nước', categoryId: 39},
  {code: 39005, typeName: 'Cảm biến oxy hòa tan trong nước', categoryId: 39},

  {code: 41001, typeName: 'Điều khiển hồng ngoại', categoryId: 41},
  {code: 41101, typeName: 'Điều hoà', categoryId: 41},
  {code: 41201, typeName: 'Quạt', categoryId: 41},
  {code: 41301, typeName: 'TIVI', categoryId: 41},

  {code: 42001, typeName: 'Ổ cắm đơn Wifi', categoryId: 42},
  {code: 42002, typeName: 'Ổ cắm thông minh Rạng Đông 4 cổng', categoryId: 42},
  {code: 42003, typeName: 'Ổ cắm thông minh Rạng Đông 6 cổng', categoryId: 42},
  {code: 42004, typeName: 'Ổ cắm kéo dài Wifi', categoryId: 42},

  {code: 43001, typeName: 'Công tắc Wifi 1 nút', categoryId: 43},
  {code: 43002, typeName: 'Công tắc Wifi 2 nút', categoryId: 43},
  {code: 43003, typeName: 'Công tắc Wifi 3 nút', categoryId: 43},
  {code: 43004, typeName: 'Công tắc Wifi 4 nút', categoryId: 43},

  {code: 44001, typeName: 'Công tắc cơ Wifi 1 nút', categoryId: 44},
  {code: 44002, typeName: 'Công tắc cơ Wifi 2 nút', categoryId: 44},
  {code: 44003, typeName: 'Công tắc cơ Wifi 3 nút', categoryId: 44},

  {code: 45001, typeName: 'Công tắc cửa cuốn wifi', categoryId: 45},
  {code: 46001, typeName: 'Công tắc chuyển mạch wifi', categoryId: 46},
  {code: 47001, typeName: 'Công tắc rèm wifi', categoryId: 22},
  
  {code: 60000, typeName: 'Converter', categoryId: 60},

  {code: 61001, typeName: 'Camera Wifi', categoryId: 61},
  {code: 61002, typeName: 'Camera Dahua', categoryId: 39},
  {code: 61003, typeName: 'Camera HikVision', categoryId: 39},
  {code: 61004, typeName: 'Camera Viettel', categoryId: 61},

  {code: 71001, typeName: 'Khoá cửa Wifi', categoryId: 71},
  {code: 72001, typeName: 'Chuông Wifi', categoryId: 72},
  {code: 73001, typeName: 'Cảm biến khói Wifi', categoryId: 73},
  {code: 74001, typeName: 'Aptomat Wifi', categoryId: 74},

  {code: 81101, typeName: 'Zone', categoryId: 81},
  {code: 81102, typeName: 'Face', categoryId: 81},

  {code: 91001, typeName: 'Bộ lặp sóng', categoryId: 91},

  {code: 101001, typeName: 'Đèn điều hoà phòng tắm', categoryId: 101},

  {code: 111001, typeName: 'Công tắc núm xoay thông minh', categoryId: 102},

  {code: 50331649, typeName: 'AIBox', categoryId: 81},
];

export const deviceTypeMap = new Map(deviceTypes.map(obj => [obj.code, obj]));



