import { NumberFilter } from "@react3l/advanced-filters";
import { AxisResponse, Dashboard, DashboardFilter } from "models/Dashboard";
import React, { CSSProperties, PropsWithChildren } from "react";
import Chart from 'react-apexcharts';
import { Observable } from "rxjs";
export const BarChart= React.memo((props: PropsWithChildren<Props>) => {
    const {loadData, filter, height, horizontal, stacked, colors} = props;

    const [data, setData] = React.useState<AxisResponse>();

    const options = {
      chart: {
        stacked: stacked??false,
      },        
      plotOptions: {
          bar: {
            horizontal: horizontal??false,
            columnWidth: '70%',
            barHeight: '50%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors:colors,
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent'],
        },
        xaxis: {
          categories: data?.horizontalAxis??[],
        },
        yaxis: {
          title: {
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `${val}`,
          },
        },
      };

      React.useEffect(() => {
        const subscription = loadData(
            {
                time: new NumberFilter({ equal: filter.time }), 
                type: new NumberFilter({ equal: filter.type }),
                skip: 0,
                take: 1,
            }
        ).subscribe({
            next: (response: AxisResponse) => {
                setData(response);
            },
            error: () => {},
        });

        return () => {
            subscription.unsubscribe();
        };
      }, [filter, loadData]);

    return (
        <>
            <Chart series={data?.series??[]} options={options} type={'bar'} height={height}/>
        </>
    );
});


export interface Props{
    filter: Dashboard
    loadData: (filter: DashboardFilter) => Observable<any>,
    style?: CSSProperties;
    stacked?: boolean;
    horizontal?: boolean;
    height?: number;
    colors?: string[];
}