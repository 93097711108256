import { Repository } from "@react3l/react3l/core";
import { kebabCase, url } from "@react3l/react3l/helpers";
import { AxiosResponse } from "axios";
import { API_DEVICE_PREFIX } from "config/api-consts";
import { BASE_API_URL } from "config/consts";
import { httpConfig } from "config/http";
import { Device } from "models/Device";
import { DeviceFilter } from "models/Device/DeviceFilter";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import nameof from "ts-nameof.macro";




export class DeviceRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = url(BASE_API_URL, API_DEVICE_PREFIX);
  }
  public deviceSatistic = (): Observable<[]> => {
    return this.httpObservable
      .post(kebabCase(nameof(this.deviceSatistic)))
      .pipe(map((response: AxiosResponse<[]>) => response.data));
  };

  public count = (deviceTypeFilter: DeviceFilter): Observable<number> => {
    return this.httpObservable
    .post<number>(kebabCase(nameof(this.count)), deviceTypeFilter)
    .pipe(map((response: AxiosResponse<number>) => response.data));
  }

  public get = (id: string): Observable<Device> => {
    return this.httpObservable
    .post(kebabCase(nameof(this.get)), id)
    .pipe(map((response: AxiosResponse<Device>) => response.data));
  }

  public list = (deviceFilter: Partial<DeviceFilter>): Observable<Device[]> => {
    return this.httpObservable
    .post(kebabCase(nameof(this.list)), deviceFilter)
    .pipe(map((response: AxiosResponse<Device[]>) => response.data));
  }

  public toolBulkMerge = (device: Device[]): Observable<Device[]> => {
    return this.httpObservable
    .post(kebabCase(nameof(this.toolBulkMerge)), device)
    .pipe(map((response: AxiosResponse<Device[]>) => response.data));
  }  

  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.httpObservable
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(map((response: AxiosResponse<void>) => response.data));
  };
}

export const deviceRepository = new DeviceRepository();
